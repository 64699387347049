import { bootstrapCoursematrix } from './coursematrix';
import { bootstrapModals } from './modals';
import { bootstrapModalTypes } from './modal-types';
import { disableVideoDownload } from './misc';
export function bootstrap() {
    bootstrapCoursematrix();
    bootstrapModals();
    bootstrapModalTypes();
    disableVideoDownload();
}
