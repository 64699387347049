<script>
    import { onMount, onDestroy, tick } from 'svelte';
    import { fade, fly } from 'svelte/transition'

    import ModalFormContent from './ModalFormContent.svelte'
    import ModalComponentContent from './ModalComponentContent.svelte'
    import ModalHtmlContent from './ModalHtmlContent.svelte'
    import ModalElementContent from './ModalElementContent.svelte'
    import ModalVideoContent from './ModalVideoContent.svelte'

    export let data = {}
    export let closeModal
    export let updateRef

    const animationDuration = 300

    const { id, title, type, onClose, onOpen } = data

    let modalElement
    let bgElement
    let contentElement
    let originalParent

    function handleBgClick(e) {
        if (e.target === bgElement) {
            e.stopPropagation() && e.preventDefault()

            close()
        }
    }

    function close() {
        closeModal(id)
    }

    const componentMap = {
        video: ModalVideoContent,
        form: ModalFormContent,
        element: ModalElementContent,
        html: ModalHtmlContent,
        component: ModalComponentContent
    }

    onMount(() => {
        updateRef(data.id, { close })
        onOpen && onOpen(modalElement)
    })

    onDestroy(() => {
        onClose && onClose(modalElement)
    })

</script>

<div bind:this={bgElement} class="modal-wrapper" on:click={handleBgClick} transition:fade={{ duration: animationDuration }}>
    <div bind:this={modalElement} class={`modal modal--${type}`} transition:fly={{ y: 100, duration: animationDuration }}>

        <div class="close-button" on:click={close}></div>

        <div class="modal-inner">

            {#if title}
            <div class="modal-title">
                {title}
            </div>
            {/if}

            <svelte:component this={componentMap[type]} data={data} on:closemodal|once={close} />
        </div>
    </div>
</div>
