<script>
    export let data = {}

    const { source } = data
</script>

<div class="modal-content">
    <video class="video-js vjs-default-skin vjs-16-9" controls controlslist="nodownload" preload="auto">
        <source src={source} type="video/mp4" />
        <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
    </video>
</div>
